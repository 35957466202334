import React, { Component } from "react";
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";
import { Dropdown, notification, Icon, Select, Switch } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";
//import SelectPerfis from "./SelectPerfis/SelectPerfis";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

function convertISODateFormat(dateString) {
    // Criando um objeto Date a partir da string de data/hora no formato ISO
    const date = new Date(dateString);

    // Extraindo dia, mês e ano
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() retorna 0-11, por isso somamos 1
    const year = date.getFullYear();

    // Extraindo hora e minuto
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Retornando no formato desejado
    return `${day}/${month}/${year}, ${hours}:${minutes}`;
}


class HeaderColaboradores extends Component {
    static contextType = GlobalContext;
    state = {
        perfis: [],
        colaboradores: [],
        anos: [],
        ano: "",
        listaAssociados: [],
        iconLoading: false
    };



    // associados = value => {
    //     if (value) {
    //         this.setState(
    //             {
    //                 loading_pesquisa: true
    //             },
    //             () => {
    //                 axios({
    //                     method: "get",
    //                     url: "/api/colaboradores/pesquisar-utilizador",
    //                     params: {
    //                         pesquisa: value
    //                     }
    //                 })
    //                     .then(response => {
    //                         this.setState({
    //                             pesquisar_utilizadores: response.data,
    //                             loading_pesquisa: false
    //                         });
    //                     })
    //                     .catch(() => {});
    //             }
    //         );
    //     } else
    //         this.setState({
    //             pesquisar_utilizadores: []
    //         });
    // };

    reenviarTodos = () => {
        this.setState({ iconLoading: true });

        axios({
            method: "put",
            url: "/api/colaboradores/reenviar-todos"
        })
            .then(() => {
                this.props.obterDados();
                openNotificationWithIcon("success", "Sucesso", "Pedidos de registo reenviados!");
                this.setState({ iconLoading: false });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível reenviar pedidos de registo!");
                this.setState({ iconLoading: false });
            });
    };

    listarAssociados = () => {
        this.setState({ iconLoading: true });

        axios({
            method: "GET",
            url: "/api/colaboradores/lista-associados"
        })
            .then((response) => {
                const listaAssociados = response.data.map(item => ({
                    id: item.id,
                    dt_criado: item.dt_criado,
                    usuariosIdsArray: item.usuarios_ids.split(',').map(id => parseInt(id, 10))
                }));

                // Atualiza o estado com a nova lista de associados
                this.setState({ listaAssociados });

                //this.setState({ listaAssociados }, () => {
                //    console.log("Lista de Associados:", this.state.listaAssociados);
                //});
            })
            .catch((error) => {
                //openNotificationWithIcon("error", "Erro", "Não foi possível carregar lista de associados!");
            });
    };

    componentDidMount() {
        this.listarAssociados();

    }


    render() {
        const { iconLoading } = this.state;
        const {
            pesquisa,
            perfilId,
            estabelecimentoId,
            acesso,
            associadosId,
        } = this.props;

        const funcionalidadesPerfis = [120, 121, 122, 123, 124, 125, 126];
        const acessoPerfis = this.context.acessos?.filter(x => funcionalidadesPerfis.includes(x.func))?.map(x => x.func);
        let perfis = [
            {
                value: 1,
                nome: "Suporte",
                func: 120
            },
            {
                value: 2,
                nome: "Gestor",
                func: 121
            },
            {
                value: 3,
                nome: "Administrativo",
                func: 122
            },
            {
                value: 4,
                nome: "Diretor",
                func: 123
            },
            {
                value: 5,
                nome: "Coordenador",
                func: 124
            },
            {
                value: 6,
                nome: "Professor",
                func: 125
            },
            {
                value: 7,
                nome: "Aluno",
                func: 126
            }
        ]

        if (acessoPerfis)
            perfis = perfis.filter(x => acessoPerfis.includes(x.func))?.map(x => x);

        return (
            <div className="header-colaboradores">
                {this.props.filtros && (
                    <div className="filtros-topo">
                        <div className="botao-procurar">
                            <BotaoProcurar
                                pesquisa={pesquisa}
                                HandleKeyPress={this.props.HandleKeyPress}
                                resetCaixaProcura={this.props.resetCaixaProcura}
                            />
                        </div>
                        <div className="select-filtro">
                            <Select
                                placeholder="Perfis"
                                value={perfilId}
                                disabled={!this.props.tem_filtro}
                                name="filtroPerfis"
                                allowClear={true}
                                onChange={this.props.filtrar}
                            >
                                {perfis.map(perfil => (
                                    <Option key={perfil.value} value={perfil.value} >{perfil.nome}</Option>
                                ))}
                                {/*<Option value="1">Suporte</Option>*/}
                                {/*<Option value="2">Gestor</Option>*/}
                                {/*<Option value="3">Administrativo</Option>*/}
                                {/*<Option value="4">Diretor</Option>*/}
                                {/*<Option value="5">Coordenador</Option>*/}
                                {/*<Option value="6">Professor</Option>*/}
                                {/*<Option value="7">Aluno</Option>*/}
                            </Select>
                        </div>
                        {this.context.estabelecimentos && this.context.estabelecimentos.length > 1 && (
                            <div className="select-filtro">
                                <Select
                                    placeholder="Estabelecimentos"
                                    value={estabelecimentoId}
                                    name="filtroEscolas"
                                    allowClear={true}
                                    onChange={this.props.filtrarOrganismo}
                                >
                                    {this.context.estabelecimentos.map(escola => (
                                        <Option key={escola.id} value={escola.id}>
                                            {escola.abreviatura}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        )}
                        {/*<div className="select-filtro">*/}
                        {/*    <Select*/}
                        {/*        placeholder="Associados"*/}
                        {/*        value={associadosId}*/}
                        {/*        // disabled={}*/}
                        {/*        name="filtroAssociados"*/}
                        {/*        allowClear={true}*/}
                        {/*        onChange={this.props.filtraAssociados}*/}
                        {/*    >*/}
                        {/*        {this.state.listaAssociados.length > 0 &&*/}
                        {/*            this.state.listaAssociados.map(item => (*/}
                        {/*                <Option key={item.id} value={item.id}>*/}
                        {/*                    {convertISODateFormat(item.dt_criado)}*/}
                        {/*                </Option>*/}
                        {/*            ))*/}
                        {/*        }*/}
                        {/*    </Select>*/}
                        {/*</div>*/}

                        <Switch
                            checkedChildren={!this.props.switch_valido ? "Ativos" : "Válidos"}
                            unCheckedChildren={!this.props.switch_valido ? "Inativos" : "Inválidos"}
                            defaultChecked
                            disabled={!this.props.tem_switch}
                            onChange={this.props.handleChangeSwitch}
                        />
                    </div>
                )}
                {this.props.botoesMensagens && (
                    <>
                        {this.props.headerTodos ? (
                            <div className="botao-enviar-mensagens" style={{ padding: "0 16px" }}>
                                <Dropdown
                                    overlay={this.props.menu}
                                    placement="topCenter"
                                    className="botao-icon-configs"
                                    trigger={["click"]}
                                >
                                    <i className={this.props.pendente ? "fas fa-cog pendente" : "fas fa-cog"} />
                                </Dropdown>
                            </div>
                        ) : null}
                        {(this.props.headerPendentes && acesso?.update) && (
                            <div className="botao-enviar-mensagens">
                                <Link
                                    to="#"
                                    className="botao-principal"
                                    onClick={this.reenviarTodos}
                                    disabled={iconLoading || this.props.nr_colaboradores <= 0}
                                    title="Reenviar mensagem de registo para todos"
                                >
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Reenviar email de registo para todos
                                </Link>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
}

export default HeaderColaboradores;
