import React, { useState, useContext, useEffect } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";
import { Icon, notification, Input, Dropdown, Drawer, Button, Menu } from "antd";
import { useHistory } from "react-router-dom";
import logo from "../../../images/logos/logo_stepforma.svg";
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";
import logoSF from "../../../images/logos/Logo sem nome.png";
/*import '../../../scss/_navbar-pagina-publica.scss';*/
import { useMediaQuery } from "react-responsive";
import { FaAlignJustify } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import SubMenu from "antd/es/menu/SubMenu";

const { Search } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

//const history = useHistory();

const Navbar = ({ estabelecimentos, entidade: { logo_entidade, imagem_propria, login_entidade } }) => {
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
    const [showNavbar, setShowNavbar] = useState(false);
    const [entidades, setEntidades] = useState([]);
    const [codigoAluno, setCodigoAluno] = useState();
    const [visibleMobileMenu, setVisibleMobileMenu] = useState(false);
    const [visibleAreaAluno, setVisibleAreaAluno] = useState(false);
    const [semNavbar, setSemNavbar] = useState("");


    const history = useHistory();

    const { entidade } = useParams();

    const context = useContext(GlobalContext);

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
    };

    const pesquisarEntidades = pesquisa => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/pesquisar-entidade",
            params: {
                pesquisa
            }
        })
            .then(response => {
                setEntidades(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível procurar canal!");
            });
    };

    const onKeyDown = event => {
        if (event.keyCode === 27) {
            context.atualizarState({
                search_entidade: false
            });
        }
    };

    useEffect(() => {
        context.atualizarState({
            search_entidade: false
        });
    }, []);

    useEffect(() => {
        if (
            history.location.pathname.split("/").pop() === "player-videoconferencia" ||
            history.location.pathname.split("/").pop() === "player-video" ||
            history.location.pathname.split("/").pop() === "player-audio" ||
            history.location.pathname.split("/").pop() === "player-pagina" ||
            history.location.pathname.split("/").pop() === "player-pdf" ||
            history.location.pathname.split("/").pop() === "player-download" ||
            history.location.pathname.split("/").pop() === "player-link" ||
            history.location.pathname.split("/").pop() === "player-sumario" ||
            history.location.pathname.split("/").pop() === "player-sessao-presencial"
        )
            setSemNavbar("sem-navbar");
        else
            setSemNavbar("");

    }, [history.location.pathname]);

    const showDrawer = () => {
        setVisibleMobileMenu(true);
    };

    const onClose = () => {
        setVisibleMobileMenu(false);
    };
    //===============================

    const validarCodigoAluno = async () => {
        try {
            if (!codigoAluno) {
                openNotificationWithIcon("error", "Erro", "O campo para colocar o código do aluno está vazio.");

                return null;
            }

            const response = await axios.get(`/api/area-aluno/existe?numeroAluno=${codigoAluno}&entidadeUrl=${entidade}`);
            if (response.data.existe) {
                history.push(`/${entidade}/areaAluno/${codigoAluno}`);
                localStorage.setItem('numeroAluno', codigoAluno)
                onClose2();
            } else {
                openNotificationWithIcon("error", "Erro", "Código de aluno não encontrado.");
            }
        } catch (error) {
            openNotificationWithIcon("error", "Erro", "Ocorreu um erro ao validar o código do aluno.");
        }
    };

    const showDrawer2 = () => {
        setVisibleAreaAluno(true);
    };

    const onClose2 = () => {
        setVisibleAreaAluno(false);
    };

    const homeUrl = estabelecimentos?.find(x => x.canal)?.url;

    const subOptions =
        (
            <ul className="grid-gap10 navbar-opcao-escolas">
                {estabelecimentos?.map((entidade, index) => (
                    <li key={index}>
                        <Link
                            to={`/${entidade.url}`}
                            onClick={() => {
                                localStorage.setItem("ultima_pagina", `/${entidade.url}`);
                                localStorage.setItem("entidade_url", entidade.url);
                            }}
                            title={entidade.nome}
                        >
                            {entidade.abrev}
                        </Link>
                    </li>
                ))}
            </ul>
        ) ?? null;

    return (
        <>
            <nav className={`navbar ${semNavbar}`}>
                <div className="container-menu2 corpo">
                    <div className="logo">
                        <Link to={`/${homeUrl}`}>
                            <img src={imagem_propria && logo_entidade ? logo_entidade : logo} alt="Logo" />
                        </Link>
                    </div>
                    <div className={`nav-elements  ${showNavbar && "active"}`}>
                        <ul>
                            <li>
                                <NavLink to={`/${homeUrl}`}>
                                    Home
                                </NavLink>
                            </li>
                            <li>
                                <Dropdown overlay={subOptions} overlayClassName="navbar-dropdown-menu">
                                    <NavLink to="#" className="dropdown-menu">
                                        Escolas
                                        <Icon type="down" />
                                    </NavLink>
                                </Dropdown>
                            </li>
                            <li>
                                <NavLink to="#">
                                    Sobre
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/${homeUrl}/contactos`}>
                                    Contato
                                </NavLink>
                            </li>
                            <li>
                                <button onClick={showDrawer2} className="botaoNav">
                                    Área do Aluno
                                </button>
                            </li>
                        </ul>

                        <Drawer
                            className="drawerAreaAluno"
                            placement="top"
                            onClose={onClose2}
                            visible={visibleAreaAluno}
                            closable={true}
                            height="auto"
                        >
                            <div className="areaAluno">
                                <div>
                                    <div className="logo">
                                        <img src="/static/media/logo_stepforma.b9bc99c9.svg" alt="Logo" />
                                    </div>

                                    <div className="descricao">
                                        <h1>Área exclusiva para Alunos</h1>

                                        <span>Utilize o código para acessar a área do aluno</span>
                                    </div>

                                    <div>
                                        <input
                                            className="inputCodigo"
                                            placeholder="Digite o código do Aluno"
                                            onChange={e => setCodigoAluno(e.target.value)}
                                            value={codigoAluno}
                                        />
                                    </div>

                                    <div>
                                        <button onClick={validarCodigoAluno} className="botao">
                                            ENTRAR
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Drawer>
                    </div>

                    {isTabletOrMobile ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <NavLink
                                to={`/${homeUrl}/login`}
                                className="login"
                                onClick={
                                    showNavbar
                                        ? () => {
                                            localStorage.removeItem("nome_curso");
                                            handleShowNavbar();
                                        }
                                        : () => localStorage.removeItem("nome_curso")
                                }
                            >
                                <button className="botao-iniciar-sessao mobile">Iniciar Sessão</button>
                            </NavLink>

                            <button onClick={showDrawer} className='icon-drawer-mobile'>
                                <FaAlignJustify size="21px" />
                            </button>

                            <Drawer title="Menu" placement="right" width="150px" onClose={onClose}
                                visible={visibleMobileMenu} closable={true}>
                                <Menu mode="inline">
                                    <Menu.Item>
                                        <Link
                                            to={`/${homeUrl}`}
                                            title={entidade?.nome}
                                            onClick={() => setVisibleMobileMenu(false)}
                                        >
                                            Home
                                        </Link>
                                    </Menu.Item>
                                    <SubMenu
                                        key="sub1"
                                        title={
                                            <span>
                                                <span>Escolas</span>
                                            </span>
                                        }
                                    >
                                        {estabelecimentos?.map(entidade => (
                                            <Menu.Item key={entidade.id}>
                                                <Link
                                                    to={`/${entidade.url}`}
                                                    onClick={() => {
                                                        localStorage.setItem("ultima_pagina", `/${entidade.url}`);
                                                        localStorage.setItem("entidade_url", entidade.url);
                                                        setVisibleMobileMenu(false);
                                                    }}
                                                    title={entidade.nome}
                                                >
                                                    {entidade.abrev}
                                                </Link>
                                            </Menu.Item>
                                        ))}
                                    </SubMenu>
                                    <Menu.Item>Sobre</Menu.Item>
                                    <Menu.Item>
                                        <Link
                                            to={`/${homeUrl}/contactos`}
                                            onClick={() => setVisibleMobileMenu(false)}
                                        >
                                            Contato
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item onClick={() => {
                                        setVisibleMobileMenu(false);
                                        showDrawer2();
                                    }}>
                                        Área do Aluno
                                    </Menu.Item>
                                </Menu>
                            </Drawer>

                        </div>
                    ) : (
                        <div>
                            <NavLink
                                    to={`/${homeUrl}/${login_entidade}`}
                                className="login"
                                onClick={
                                    showNavbar
                                        ? () => {
                                            localStorage.removeItem("nome_curso");
                                            handleShowNavbar();
                                        }
                                        : () => localStorage.removeItem("nome_curso")
                                }
                            >
                                <button className="botao-iniciar-sessao">Iniciar Sessão</button>
                            </NavLink>
                        </div>
                    )}
                </div>
            </nav>
            {context.search_entidade && (
                <div className="search-fullscreen" onKeyDown={onKeyDown}>
                    <div className="container">
                        <Icon type="close" className="close-search" onClick={() => context.atualizarState({ search_entidade: false })} />
                        <Search placeholder="Procurar Canal" name="inputPesquisa" autoComplete="off" onSearch={pesquisarEntidades} />
                        <div className="listagem-entidades">
                            {entidades.map((entidade, index) => (
                                <Link
                                    key={index}
                                    to={
                                        entidade.nome_abrev
                                            ? entidade.dominio
                                                ? `https://${entidade.dominio}/${entidade.nome_abrev}`
                                                : `/${entidade.nome_abrev}`
                                            : `https://${entidade.dominio}`
                                    }
                                    className="entidade"
                                >
                                    <div className="container-img">
                                        <img src={entidade.logo ? entidade.logo : logoSF} />
                                    </div>
                                    <span className="nome">{entidade.nome}</span>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Navbar;
