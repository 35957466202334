import React, { useState, useEffect } from "react";
import {
    DatePicker,
    Drawer,
    Icon,
    notification,
    Form
} from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerDataLimite = ({ Id, utilizadorId, avaliacaoId, visible, onClose, form }) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [dataLimite, setDataLimite] = useState(null)

    useEffect(() => {
        if (visible) {
            buscarDataLimite();
        }
    }, [visible]);

    const guardar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);

                axios.post('/api/turma/alterar-data-limite-nota', {
                    notaId: Id,
                    avaliacaoId,
                    utilizadorId,
                    data: data.format('YYYY-MM-DD')
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Data alterada com sucesso!");
                        onClose();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Erro ao alterar a data.");

                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        })
    };

    const handleDateChange = (date) => {
        setData(date); 
    };

    const buscarDataLimite = () => {
        axios.get('/api/turma/data-limite-nota', {
            params: {
                Id,
                avaliacaoId
            }
        })
            .then(response => {
                setDataLimite(response.data.dataAlterarNota)
            })
            .catch(error => {

            })
    };

    const afterVisibleChange = aberto => {
        if (aberto) {
            form.resetFields();
            setData("")
        }
    };

    const { getFieldDecorator } = form;
    
    return (
        <>
            <Drawer
                title="Alterar data limite nota"
                className="drawer-add-cursos drawer-logs"
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={afterVisibleChange}
            >
                <Form className="form-categorias" layout="horizontal">
                    <div className="bloco">
                        <span className="dataLimite">Data limite preenchimento: {new Date(dataLimite).toLocaleDateString(("pt-BR"))}</span>
                        <Form.Item>
                            {getFieldDecorator("data", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: data
                            })(
                                <DatePicker size={"large"} onChange={handleDateChange} />
                            )}
                        </Form.Item>
                    </div>
                </Form>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    <button className="botao-principal" onClick={guardar} disabled={loading} type="primary">
                        {loading ? <Icon type="loading" /> : null}
                        Guardar
                    </button>
                </div>
            </Drawer>
        </>
    );
}

const FormDrawerDataLimite = Form.create({ name: "form-drawer-data-limite" })(DrawerDataLimite);

export default FormDrawerDataLimite;