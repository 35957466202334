import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon, Table, Form, Input, notification, Select, Modal } from "antd";
import axios from "axios";
import moment from "moment";

import ModalGerarDocumento from "../../../../Componentes/Modals/GerarDocumento";
import ModalLoading from "../../../../Componentes/Modals/ModalLoading";

import { validarExistemNotas, validarExistemObs } from "../../../../Utilities/Diario/Aproveitamento/validacoes";

import { GlobalContext } from "../../../../GlobalState";

const { Option } = Select;
const { TextArea } = Input;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
    state = {
        editing: false,
        classificacoes: []
    };

    UNSAFE_componentWillMount() {
        this.info();
    }

    toggleEdit = () => {
        const { record } = this.props;

        if (record.permissao || record.permissaoAdm) {
            const editing = !this.state.editing;
            this.setState({ editing }, () => {
                if (editing) {
                    this.input.focus();
                }
            });
        }
    };

    save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            this.toggleEdit();

            handleSave({ ...record, ...values });
        });
    };

    saveSelect = e => {
        const { record, handleSave } = this.props;
        const cor = this.state.classificacoes.find(x => x.nome === e)?.cor;

        handleSave({ ...record, nota: e, cor });
        this.toggleEdit();
    };

    renderCell = form => {
        this.form = form;
        const { children, dataIndex, record, title } = this.props;
        const { editing } = this.state;
        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    //rules: [
                    //    {
                    //        required: dataIndex != "observacoes",
                    //        message: "Campo obrigatório",
                    //    },
                    //],
                    initialValue: record[dataIndex]
                })(
                    dataIndex === "nota" ? (
                        <Select
                            placeholder="Selecionar"
                            ref={node => (this.input = node)}
                            onPressEnter={this.saveSelect}
                            onBlur={this.saveSelect}
                            style={{ width: 100 }}
                        >
                            {this.state.classificacoes.map((classificacao, index) => (
                                <Option key={index} value={classificacao.nome}>
                                    {classificacao.nome}
                                </Option>
                            ))}
                        </Select>
                    ) : (
                        <TextArea
                            ref={node => (this.input = node)}
                            onPressEnter={this.save}
                            onBlur={this.save}
                            rows={4}
                            style={{ height: 100 }}
                        />
                    )
                )}
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={this.toggleEdit}>
                {children}
            </div>
        );
    };

    info = () => {
        axios({
            method: "get",
            url: "/api/aproveitamento/conceitos-classificacoes",
            params: {
                estruturaId: JSON.parse(localStorage.getItem("aproveitamento")).estrutura
            }
        })
            .then(response => {
                this.setState({
                    classificacoes: response.data
                });
            })
            .catch(error => {});
    };

    render() {
        const { editable, dataIndex, title, record, index, handleSave, children, ...restProps } = this.props;
        return <td {...restProps}>{editable ? <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer> : children}</td>;
    }
}

const Detalhe = ({ match }) => {
    const [aluno, setAluno] = useState("");
    const [avaliacoes, setAvalicoes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visibleExport, setVisibleExport] = useState(false);
    const [buttonDownload, setButtonDownload] = useState(false);
    const [exportFile, setExportFile] = useState("");
    const [loadingText, setLoadingText] = useState("");
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [grupoInfo, setGrupoInfo] = useState("");

    const [estado, setEstado] = useState("");
    const [texto, setTexto] = useState("");
    const [campoCalculado, setCampoCalculado] = useState(false);

    const context = useContext(GlobalContext);

    const entidadeId = localStorage.getItem('entidade_id');

    const acessoApagarNota = context.acessos?.find(x => x.func === 25);
    const acessoApagarConteudo = context.acessos?.find(x => x.func === 25);

    const handleSave = row => {
        const lista = [...avaliacoes];
        const index = lista.findIndex(item => item.id === row.key);
        const avaliacao = lista[index];

        lista.splice(index, 1, {
            ...avaliacao,
            ...row
        });

        setAvalicoes([...lista]);

        if (estado !== "") setEstado("");
    };

    const guardar = () => {
        if (validarExistemNotas(avaliacoes) || validarExistemObs(avaliacoes) && entidadeId == 3) {
            const lista = avaliacoes.map(avaliacao => {
                return {
                    id: avaliacao.key,
                    nota: avaliacao.nota,
                    observacoes: avaliacao.observacoes
                };
            });

            let item = new FormData();
            item.append("turmaId", localStorage.getItem("codigo_curso"));
            item.append("code", match.params.code);
            item.append("pauta", JSON.stringify(lista));

            axios({
                method: "put",
                url: "/api/turma/guardar-pauta-conceitos-aluno",
                data: item
            })
                .then(response => {
                    openNotificationWithIcon("success", "Sucesso", "Resultados guardados.");
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível guardar os resultados.");
                });
        } else {
            if (entidadeId == 3)
                openNotificationWithIcon("error", "Erro", "Não foram adicionadas notas ou observações!");
            else
                openNotificationWithIcon("error", "Erro", "Não foram adicionadas notas!");
        }
    };

    const listar = () => {
        const grupo = JSON.parse(localStorage.getItem("grupo"));

        setLoading(true);

        axios({
            method: "get",
            url: "/api/aproveitamento/listar-items-avaliacao-conceitos-subitems-aluno",
            params: {
                disciplinaId: localStorage.getItem("codigo_curso"),
                grupoId: grupo.id,
                code: match.params.code,
                estruturaId: JSON.parse(localStorage.getItem("aproveitamento")).estrutura
            }
        })
            .then(response => {
                let avaliacoes = [];

                response.data.map(item => {
                    let data = "";
                    let estado = "";

                    if (item.guardado) {
                        data = moment(item.dt_guardado).format("DD/MM/YYYY");
                        estado = "Por publicar";
                    }

                    if (item.publicado) {
                        data = moment(item.dt_publicado).format("DD/MM/YYYY");
                        estado = "Publicado";
                    }

                    if (item.validado) {
                        data = moment(item.dt_validado).format("DD/MM/YYYY");
                        estado = "Validado";
                    }

                    avaliacoes.push({
                        key: item.id,
                        id: item.id,
                        idPai: item.idPai,
                        code: item.code,
                        epoca: item.epoca,
                        nome: item.titulo,
                        autor: item.autor,
                        data: data,
                        estado: estado,
                        ordem: item.ordem,
                        nota: item.nota,
                        cor: item.cor,
                        observacoes: item.observacoes,
                        permissao: item.permissao,
                        permissaoAdm: item.permissaoAdm
                    });
                });

                setAvalicoes(avaliacoes);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.data) openNotificationWithIcon("error", "Erro", error.response.data);
            });
    };

    const info = () => {
        const grupo = JSON.parse(localStorage.getItem("grupo"));

        axios({
            method: "get",
            url: "/api/turma/info-grupo",
            params: {
                grupoId: grupo.id
            }
        })
            .then(response => {
                if (response.data) {
                    if (response.data !== "CAMPO_CALCULADO") {
                        setEstado(response.data.estado);
                        setTexto(response.data.texto);
                        setCampoCalculado(response.data.campoCalculado);
                    } else setCampoCalculado(true);
                }
            })
            .catch(error => {});
    };

    const exportarExcel = () => {
        setVisibleExport(true);
        setButtonDownload(true);
        axios({
            method: "get",
            url: "/api/excel/exportar-conceitos-aluno",
            params: {
                disciplinaId: localStorage.getItem("codigo_curso"),
                estruturaId: JSON.parse(localStorage.getItem("aproveitamento")).estrutura,
                code: match.params.code
            }
        })
            .then(response => {
                setButtonDownload(false);
                setExportFile(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                setVisibleExport(false);
            });
    };

    const importarExcel = ficheiro => {
        setVisibleLoading(true);
        setLoadingText("A importar registos...");

        let item = new FormData();
        item.append("code", match.params.code);
        item.append("ficheiro", ficheiro);

        axios({
            method: "put",
            url: "/api/excel/importar-conceitos-aluno",
            data: item
        })
            .then(() => {
                setVisibleLoading(false);
                listar();
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
                setVisibleLoading(false);
            });
    };

    const validarFormato = formato => {
        var formatos = ["xlsx"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    const uploadFoto = event => {
        if (validarFormato(event.target.files[0].name.split(".").pop().toLowerCase())) {
            importarExcel(event.target.files[0]);
            document.getElementById("upload-doc").value = "";
        } else {
            document.getElementById("upload-doc").value = "";
            openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");
        }
    };

    const components = {
        body: {
            row: EditableFormRow,
            cell: EditableCell
        }
    };

    useEffect(() => {
        listar();
        info();
        setGrupoInfo(JSON.parse(localStorage.getItem("grupo")));
        if (localStorage.getItem(match.params.code)) {
            setAluno(localStorage.getItem(match.params.code));
        }
    }, []);

    const acesso = context.acessos?.find(x => x.func === 21);

    const editavel = acesso?.update; //(estado === "" || estado === "GUARDADO" || estado === "SUBMETIDO") && !campoCalculado && acesso?.update;
    const columns = [
        {
            title: "Pauta",
            dataIndex: "nome",
            render: (text, record) => text
        },
        {
            title: "Nota",
            dataIndex: "nota",
            width: 150,
            editable: editavel,
            render: (text, record) => (
                <span className="grupo-inserir-nota">
                    {text ? (
                        <span className={`tags-aproveitamento ${record.cor}`}>{text}</span>
                    ) : (
                        <span className="campo-inserir-nota">
                            <strong></strong>
                        </span>
                    )}

                    {acessoApagarNota && record.nota && editavel && (
                        <Link
                            to="#"
                            className="botao-icon-inativar"
                            onClick={event => {
                                event.stopPropagation();
                                if (!record.guardadaBD) {
                                    apagarCampoNaoGuardado(record.key, "nota", "Pretende apagar a nota?");
                                } else {
                                    apagarNota(record.notaId);
                                }
                            }}
                        >
                            <Icon type="close" />
                        </Link>
                    )}
                </span>
            )
        },
        {
            title: "Observações",
            dataIndex: "observacoes",
            editable: editavel,
            render: (text, record) => (
                <span className="campo-inserir-nota">
                    <strong>{text ? (text.length > 20 ? `${text.substring(0, 20)}...` : text) : ""}</strong>
                </span>
            )
        }
    ];

    const apagarNota = notaId => {
        confirm({
            title: "Pretende apagar a nota?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/aproveitamento/apagar-nota",
                    params: {
                        notaId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Nota apagada.");

                        const registo = avaliacoes.find(x => x.notaId === notaId);
                        const updatedRecord = { ...registo, nota: null };
                        const updatedAvaliacoes = avaliacoes.map(registro => {
                            if (registro.key === updatedRecord.key) {
                                return updatedRecord;
                            }
                            return registro;
                        });
                        setAvalicoes(updatedAvaliacoes);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível apagar a nota.");
                    });
            }
        });
    };

    const apagarCampoNaoGuardado = (notaId, campo, title) => {
        confirm({
            title: [title],
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                const registo = avaliacoes.find(x => x.key === notaId);
                const updatedRecord = { ...registo, [campo]: null };
                const updatedAvaliacoes = avaliacoes.map(registro => {
                    if (registro.key === updatedRecord.key) {
                        return updatedRecord;
                    }
                    return registro;
                });

                setAvalicoes(updatedAvaliacoes);
            }
        });
    };

    const columnsClick = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave
            })
        };
    });

    return (
        <div className="container container-body">
            <div className="turma-resultados">
                <div className="controlos controlos-fundo" style={{ justifyContent: "flex-end" }}>
                    <div className="bloco-direita">
                        {/*<Link className="botao-icon-pdf botao-maior" to="#" title="Descarregar PDF">*/}
                        {/*    <Icon type="file-pdf" />*/}
                        {/*</Link>*/}
                        {!campoCalculado && acesso?.update && (
                            <Link
                                className={`botao-icon-print botao-maior ${
                                    estado === "PUBLICADO" || estado === "VALIDADO" ? "botao-disabled" : ""
                                }`}
                                disabled={estado === "VALIDADO"}
                                to="#"
                                title="Importar"
                                onClick={() => document.getElementById("upload-doc").click()}
                            >
                                <Icon type="upload" />
                            </Link>
                        )}
                        <Link className="botao-icon-download botao-maior" to="#" title="Exportar" onClick={exportarExcel}>
                            <Icon type="download" />
                        </Link>
                        <Link
                            to={`/gerir-detalhe-turma/${match.params.turma
                                .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                .toLowerCase()}/aproveitamento-conceitos-lista-alunos`}
                            className="botao-secundario botao-secundario-redondo"
                            style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                        >
                            <Icon type="arrow-left" />
                        </Link>
                    </div>
                </div>
                <div className="bloco">
                    <div className="info" style={{ display: "block" }}>
                        <div className="info-header-conceitos">
                            <div style={{ fontSize: 16 }}>{grupoInfo.nome}</div>
                            <div style={{ fontSize: 20 }}>{aluno}</div>
                            <p className="estado" dangerouslySetInnerHTML={{ __html: texto }}></p>
                        </div>
                    </div>
                    <Table
                        id="cursos-disciplinas"
                        loading={{
                            spinning: loading,
                            indicator: (
                                <div className="loading-data-table">
                                    <div className="loading" />
                                </div>
                            )
                        }}
                        components={components}
                        columns={columnsClick}
                        dataSource={avaliacoes}
                        pagination={false}
                        locale={{ emptyText: "Não existem dados!" }}
                    />
                    <div className="bloco-controls">
                        <Link className="botao-principal" to="#" onClick={guardar}>
                            Salvar
                        </Link>
                    </div>
                </div>
            </div>
            <ModalGerarDocumento
                visibleExportar={visibleExport}
                ficheiroExportar={exportFile}
                btnDescarregar={buttonDownload}
                onClose={() => setVisibleExport(false)}
            />
            <ModalLoading visible={visibleLoading} text={loadingText} />
            <input
                type="file"
                id="upload-doc"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={uploadFoto}
                style={{ display: "none" }}
            />
        </div>
    );
};

export default withRouter(Detalhe);
