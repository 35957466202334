import React, { Component } from "react";
import axios from "axios";
import noimage from "../../../images/noimage.png";
import moment from "moment";
import { Link } from "react-router-dom";
import { Icon, notification } from "antd";
import ModalAta from "../../../GerirDetalheTurma/Aproveitamento/Relatorios/Drawer/Ata";
import ModalViewer from "../../../Componentes/Modals/ModalViewer";
import ModalLoading from "../../../Componentes/Modals/ModalLoading";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ParecerAluno extends Component {
    state = {
        ataVisible: false,
        participanteId: 0,
        aulaId: 0,
        visibleViewer: false,
        visibleLoading: false,
        docs: []
    };

    parecerDescritivoIndividual = (userId, aulaId, dataEmissao) => {
        this.setState(
            {
                visibleLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/pdf/parecer-descritivo",
                    params: {
                        utilizadorId: userId,
                        aulaId: aulaId,
                        dataEmissao: dataEmissao.format("YYYY-MM-DD")
                    }
                })
                    .then(response => {
                        this.setState({
                            visibleLoading: false,
                            visibleViewer: true,
                            docs: [{ uri: response.data }]
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleLoading: false
                        });
                    });
            }
        );
    };

    montarAlunos = aula => {
        if (!aula.pareceres) return "Sem dados...";

        const pareceres = JSON.parse(aula.pareceres);
        let registos = pareceres
            .filter(x => x.texto)
            .map(parecer => {
                var participante = this.props.participantes.find(x => x.id === parecer.id);
                if (!participante) return null;

                const ativo =
                    participante.ativo ||
                    (!participante.ativo && moment(participante.dataAtualizado).isSameOrAfter(moment(aula.dt_inicio)));

                if (ativo)
                    return (
                        <li className="item-observacoes">
                            <div
                                className="info-participante"
                                style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}
                            >
                                <div className="container-img">
                                    <img src={participante.foto ? participante.foto : noimage} />
                                </div>
                                <div className="info" style={{ flex: "fit-content" }}>
                                    <span className="nome">{participante.nome}</span>
                                    <p className="numero">{participante.numero}</p>
                                </div>
                                <div className="bloco-opcoes-esquerda" style={{ alignSelf: "flex-end" }}>
                                    <Link
                                        className="botao-icon-parecer-aluno"
                                        to="#"
                                        title="Visualizar"
                                        onClick={event => {
                                            event.stopPropagation();
                                            this.setState({
                                                ataVisible: true,
                                                participanteId: participante.id,
                                                aulaId: aula.id
                                            });
                                        }}
                                    >
                                        <Icon type="profile" />
                                    </Link>
                                </div>
                            </div>
                            <p>{parecer.texto}</p>
                        </li>
                    );
            });

        return registos;
    };

    render() {
        const { aula, key } = this.props;

        const alunos = this.montarAlunos(aula);

        const { ataVisible, participanteId, aulaId, docs, visibleLoading, visibleViewer } = this.state;

        if (!alunos) return null;

        return (
            <>
                <div key={key} className="bloco-diario-observacoes">
                    <div className="observacao-data-aula">
                        {aula.curso ? <span>{aula.curso}</span> : <span> {aula.titulo}</span>}
                        <span>
                            {moment(aula.dt_inicio).format("DD/MM/YYYY")} <i className="fas fa-circle separador-data-hora"></i>{" "}
                            {aula.hr_inicio} - {aula.hr_termino}
                        </span>
                    </div>
                    <ul className="lista-observacoes">{alunos}</ul>
                </div>
                <ModalLoading visible={visibleLoading} text="A gerar documento..." />
                <ModalViewer visible={visibleViewer} docs={docs} onCancel={() => this.setState({ visibleViewer: false })} />
                <ModalAta
                    visible={ataVisible}
                    onClose={() =>
                        this.setState({
                            ataVisible: false
                        })
                    }
                    onConfirm={dataEmissao => {
                        this.parecerDescritivoIndividual(participanteId, aulaId, dataEmissao);
                        this.setState({
                            ataVisible: false
                        });
                    }}
                />
            </>
        );
    }
}

export default ParecerAluno;
