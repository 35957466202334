import React, { useEffect, useState, useRef } from 'react';
import { notification } from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

export default function Carousel({ entidade }) {
    const [loading, setLoading] = useState(true);
    const [slides, setSlides] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const intervalRef = useRef(null);
    const timeoutRef = useRef(null);
    const intervalTime = 8000; // Tempo em milissegundos para passar para o próximo slide
    const delayAfterInteraction = 10000; // Tempo de espera após interação manual

    const fetchImages = async () => {
        const url = `/api/pagina-publica/carrocel/listar`;
        try {
            const response = await axios.get(url, {
                params: { entidadeUrl: entidade }
            });
            setSlides(response.data);
        } catch (error) {
            openNotificationWithIcon("error", "Erro", "Não foi possível carregar as imagens.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchImages();
    }, [entidade]);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        resetTimeout();
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
        resetTimeout();
    };

    const goToSlide = (index) => {
        setCurrentSlide(index);
        resetTimeout();
    };

    const resetInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, intervalTime);
    };

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            resetInterval();
        }, delayAfterInteraction);
    };

    useEffect(() => {
        resetInterval();
        return () => {
            clearInterval(intervalRef.current);
            clearTimeout(timeoutRef.current);
        };
    }, [slides.length]);

    return (
        <div className="carousel-container carousel-principal">
            <div className="carousel" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                {loading ?
                    <div className="skeleton-carousel shimmer"></div>
                    :
                    slides.map((slide, index) => (
                        <div key={index} className={`slide ${index === currentSlide ? 'active' : ''}`}>
                            <img src={slide.caminho} alt={`Slide ${index + 1}`} />
                        </div>
                    ))
                }
            </div>
            {slides.length > 1 &&
                <>
                    <button className="prev" onClick={prevSlide}>❮</button>
                    <button className="next" onClick={nextSlide}>❯</button>
                    <div className="dots">
                        {slides.map((_, index) => (
                            <span key={index} className={`dot ${index === currentSlide ? 'active' : ''}`}
                                onClick={() => goToSlide(index)}>
                                <div></div>
                            </span>
                        ))}
                    </div>
                </>}
        </div>
    );
};



